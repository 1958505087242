import React, { useEffect, useState } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext, HostContext } from '../context'
import { error, success } from '../util'
const Page_profile = () => {
  document.title = "Profil | AKUOI"
  const { STATE_LOGIN, DISPATCH_LOGIN } = useContext(AuthContext)
  const { HOST } = useContext(HostContext)
  const [nama_depan, set_nama_depan] = useState("")
  const [nama_belakang, set_nama_belakang] = useState("")
  const [nik, set_nik] = useState("")
  const [nip, set_nip] = useState("")
  const [unit_kerja, set_unit_kerja] = useState("")
  const [jabatan, set_jabatan] = useState("")
  const [pangkat, set_pangkat] = useState("")
  const [no_telp, set_no_telp] = useState("")
  const [pertanyaan_keamanan, set_pertanyaan_keamanan] = useState("")
  const [pertanyaan_jawaban, set_pertanyaan_jawaban] = useState("")
  const [opd, set_opd] = useState([])
  const [aktif_data_pribadi, set_aktif_data_pribadi] = useState(true)
  const [aktif_ubah_sandi, set_aktif_ubah_sandi] = useState(false)
  const [list_pangkat, set_list_pangkat] = useState([])

  const [sandi_lama, set_sandi_lama] = useState("")
  const [sandi_baru, set_sandi_baru] = useState("")
  const [sandi_baru_2, set_sandi_baru_2] = useState("")



  useEffect(() => {
    const getOpd = async () => {
      const requ = await fetch(`https://sikkap.kotamobagu.go.id/webserv/opd.php`)
      const resu = await requ.json()
      set_opd(resu)
    }
    const getPangkat = async () => {
      const requ = await fetch(`https://sikkap.kotamobagu.go.id/webserv/pangkat.php`)
      const resu = await requ.json()
      set_list_pangkat(resu)
    }
    getOpd()
    getPangkat()
  }, [])

  const handleUbahDataPribadi = async (e) => {
    e.preventDefault()
    const fd = new FormData()
    fd.append('nama_depan', nama_depan)
    fd.append('nama_belakang', nama_belakang)
    fd.append('nik', nik)
    fd.append('nip', nip)
    fd.append('unit_kerja', unit_kerja)
    fd.append('jabatan', jabatan)
    fd.append('pangkat', pangkat)
    fd.append('notelp', no_telp)
    fd.append('pertanyaan', pertanyaan_keamanan)
    fd.append('jawaban', pertanyaan_jawaban)

    const requ = await fetch(`${HOST}/profil/simpan`, {
      method: 'POST',
      headers: {
        "xid": STATE_LOGIN.session
      },
      body: fd
    })
    try {
      const resu = await requ.json()
      if (resu.code === 0) {
        DISPATCH_LOGIN({ type: 'SET_NEW_SESSION', session: resu.session })
        success({ msg: resu.message })
      } else {
        error({ msg: resu.message })
      }
    } catch (error) {
      error({ msg: error.message })
    }
  }

  const handleUbahPassword = async (e) => {
    e.preventDefault()
    const fd = new FormData()
    fd.append('sandi_lama', sandi_lama)
    fd.append('sandi_baru', sandi_baru)
    fd.append('ulang_baru', sandi_baru_2)

    const requ = await fetch(`${HOST}/profil/sandi`, {
      method: "POST",
      headers : {
        "xid" : STATE_LOGIN.session
      },
      body: fd
    })
    
    try {
      const resu = await requ.json()
      if (resu.code === 0) {
        DISPATCH_LOGIN({ type: 'SET_NEW_SESSION', session: resu.session })
        success({ msg: resu.message })
      } else {
        error({ msg: resu.message })
      }
    } catch (e) {
      error({ msg: e.messaeg })
    }

  }

  const direct = useNavigate()
  if (!STATE_LOGIN.is_login) {
    return direct('/')
  }


  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">
          <div className="block">
            <div className="block-header">
              <h3 className='block-title'>Profil</h3>
            </div>
            <ul className="nav nav-tabs nav-tabs-alt" data-toggle="tabs" role="tablist">
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <a href className={aktif_data_pribadi ? "nav-link active" : "nav-link"} onClick={() => { set_aktif_ubah_sandi(false); set_aktif_data_pribadi(true) }}>Data Pribadi</a>
              </li>
              <li className="nav-item" style={{ cursor: 'pointer' }}>
                <a href className={aktif_ubah_sandi ? "nav-link active" : "nav-link"} onClick={() => { set_aktif_data_pribadi(false); set_aktif_ubah_sandi(true) }}>Ubah Sandi</a>
              </li>
            </ul>
            <div className="block-content tab-content">
              <div className={aktif_data_pribadi ? 'tab-pane active' : 'tab-pane'} role="tabpanel">
                <form className='mt-3' onSubmit={handleUbahDataPribadi}>
                  <div className="form-group">
                    <label htmlFor="nama_depan">Nama Depan</label>
                    <input type="text" id="nama_depan" name="nama_depan" className="form-control" value={nama_depan} onChange={e => set_nama_depan(e.target.value)} autoComplete="off"/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="nama_belakang">Nama Belakang</label>
                    <input type="text" id="nama_belakang" name="nama_belakang" className="form-control" value={nama_belakang} onChange={e => set_nama_belakang(e.target.value)} autoComplete="off"/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="nik">NIK</label>
                    <input type="text" id="nik" name="nik" className="form-control" value={nik} onChange={e => set_nik(e.target.value)} autoComplete="off"/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="nip">NIP</label>
                    <input type="text" id="nip" name="nip" className="form-control" value={nip} onChange={e => set_nip(e.target.value)} autoComplete="off"/>
                  </div>


                  <div className="form-group">
                    <label htmlFor="unit_kerja">Unit Kerja</label>
                    <select className='form-control' id="unit_kerja" name="unit_kerja" value={unit_kerja} onChange={e => set_unit_kerja(e.target.value)} >
                      <option>--Pilih--</option>
                      {
                        opd.map(v => { return <option value={v} key={v}>{v}</option> })
                      }
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="jabatan">Jabatan</label>
                    <input type="text" id="jabatan" name="jabatan" className="form-control" value={jabatan} onChange={e => set_jabatan(e.target.value)} autoComplete="off"/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="pangkat">Pangkat</label>
                    <select className="form-control" id="pangkat" name="pangkat" value={pangkat} onChange={e=>set_pangkat(e.target.value)} required>
                      <option>-Pilih-</option>
                      {
                        list_pangkat.map(v => { return <option key={v}>{v}</option> })
                      }
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="no_telp">No. Telp</label>
                    <input type="text" id="no_telp" name="no_telp" className="form-control" value={no_telp} onChange={e => set_no_telp(e.target.value)} autoComplete="off"/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="pertanyaan_keamanan">Pertanyaan Keamanan</label>
                    <input type="text" id="pertanyaan_keamanan" name="pertanyaan_keamanan" value={pertanyaan_keamanan} onChange={e => set_pertanyaan_keamanan(e.target.value)} className="form-control" autoComplete="off"/>
                  </div>

                  <div className="form-group">
                    <label htmlFor="pertanyaan_jawaban">Jawaban</label>
                    <input type="text" id="pertanyaan_jawaban" name="pertanyaan_jawaban" value={pertanyaan_jawaban} onChange={e => set_pertanyaan_jawaban(e.target.value)} className="form-control" autoComplete="off"/>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button type="submit" style={{ margin: 5, padding: 10, backgroundColor: '#273fab', color: '#fff', border: 0, }} className='my-2 float-right'>Simpan</button>
                    </div>
                  </div>
                </form>
              </div>
              <div className={aktif_ubah_sandi ? 'tab-pane active' : 'tab-pane'} id="ubah_sandi" role="tabpanel">
                <form className='mt-3' onSubmit={handleUbahPassword}>
                  <div className="form-group">
                    <label htmlFor="sandi_lama">Kata Sandi Lama</label>
                    <input type="password" id="sandi_lama" name="sandi_lama" className="form-control" value={sandi_lama} onChange={e => set_sandi_lama(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label htmlFor="sandi_baru">Kata Sandi Baru</label>
                    <input type="password" id="sandi_baru" name="sandi_baru" className="form-control" value={sandi_baru} onChange={e => set_sandi_baru(e.target.value)} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="sandi_baru_2">Ulangi Kata Sandi Baru</label>
                    <input type="password" id="sandi_baru_2" name="sandi_baru_2" className="form-control" value={sandi_baru_2} onChange={e => set_sandi_baru_2(e.target.value)} />
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <button type="submit" style={{ margin: 5, padding: 10, backgroundColor: '#273fab', color: '#fff', border: 0, }} className='my-2 float-right'>Simpan</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page_profile