import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { AuthContext } from '../context'
import { IoLogOutOutline } from "react-icons/io5";

const Sidebar = () => {
  const direct = useNavigate();
  const { STATE_LOGIN, DISPATCH_LOGIN } = React.useContext(AuthContext)
  const handleLogout = () => {
    DISPATCH_LOGIN({ type: 'LOGOUT' });
    direct('/');
  }
  return (
    <>

      <nav id="sidebar" aria-label="Main Navigation">
        {/* Side Header  */}
        <div className="content-header bg-white-5">
          {/* Logo  */}
          <a className="font-w600 text-dual" href="index.html">
            <i className="fa fa-users text-primary"></i>
            <span className="smini-hide">
              <span className="font-w700 font-size-h5"> e-Akun</span> <span className="font-w400"></span>
            </span>
          </a>
          {/* END Logo  */}

          {/* Options  */}
          <div>
            {/* Close Sidebar, Visible only on mobile screens  */}
            {/* Layout API, functionality initialized in Template._uiApiLayout()  */}
            <a className="d-lg-none text-dual ml-3" data-toggle="layout" data-action="sidebar_close" href>
              <i className="fa fa-times"></i>
            </a>
            {/* END Close Sidebar  */}
          </div>
          {/* END Options  */}
        </div>
        {/* END Side Header  */}

        {/* Side Navigation  */}
        <div className="content-side content-side-full">
          <ul className="nav-main">
            <li className="nav-main-item">
              <NavLink className="nav-main-link" to="/dashboard">
                <i className="nav-main-link-icon si si-speedometer"></i>
                <span className="nav-main-link-name">Beranda</span>
              </NavLink>
            </li>
            <li className="nav-main-item">
              <NavLink className="nav-main-link" to="/profile">
                <i className="nav-main-link-icon si si-user"></i>
                <span className="nav-main-link-name">Profil</span>
              </NavLink>
            </li>
            {
              STATE_LOGIN.gid !== 1
                ?
                <li className="nav-main-item">
                  <NavLink className="nav-main-link" to="/daftar-pengguna">
                    <i className="nav-main-link-icon si si-users"></i>
                    <span className="nav-main-link-name">Daftar Pengguna</span>
                  </NavLink>
                </li>
                :
                ''
            }

            <li className="nav-main-item">
              <a className="nav-main-link" onClick={handleLogout} style={{ cursor: 'pointer' }} href>
                {/* <i className="nav-main-link-icon si si-sign-out"></i> */}
                {<IoLogOutOutline className='nav-main-link-icon' size={20} />}
                <span className="nav-main-link-name">Keluar</span>
              </a>
            </li>
          </ul>
        </div>
        {/* END Side Navigation  */}
      </nav>
      {/* END Sidebar --></d */}
    </>
  )
}

export default Sidebar