import React, { useEffect, useState, useContext } from 'react'
import '../assets/style.css'
import { BsCheck, BsDownload, BsInfoCircle, BsX } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom';
import { BarLoader } from 'react-spinners';
import { HostContext } from '../context';
import { bytesToSize, error } from '../util';

/**
  * Component : Register
  * created by : Bobi R. Simbala
  * 
*/
const Register = () => {

  const direct = useNavigate()
  const [check_valid, set_check_valid] = useState({ status: true, loading: false, status_msg_netral: true, status_msg_true: false, status_msg_false: false, })
  const [view_step1, set_view_step1] = useState(true)
  const [view_step2, set_view_step2] = useState(false)
  const [view_step3, set_view_step3] = useState(false)
  const [view_detail, set_view_detail] = useState(false)
  const [view_finish, set_view_finish] = useState(false)
  const [data_finish, set_data_finish] = useState(false)
  const [nama_pengguna, set_nama_pengguna] = useState("")
  const [subdomain, set_subdomain] = useState("@asn.kotamobagu.go.id")
  const [nama_depan, set_nama_depan] = useState("")
  const [nama_belakang, set_nama_belakang] = useState("")
  const [nik, set_nik] = useState("")
  const [nip, set_nip] = useState("")
  const [unit_kerja, set_unit_kerja] = useState("")
  const [jabatan, set_jabatan] = useState("")
  const [pangkat, set_pangkat] = useState("")
  const [no_telp, set_no_telp] = useState("")
  const [pertanyaan_keamanan, set_pertanyaan_keamanan] = useState("")
  const [pertanyaan_jawaban, set_pertanyaan_jawaban] = useState("")
  const [file_rekom, set_file_rekom] = useState("")
  const [file_ktp, set_file_ktp] = useState("")
  const [token, set_token] = useState("")
  const [link, set_link] = useState("")
  const { HOST } = useContext(HostContext)
  const [opd, set_opd] = useState([])
  const [list_pangkat, set_list_pangkat] = useState([])
  const [loading, set_loading] = useState(false)
  const [title, set_title] = useState("Langkah 1 | Registrasi | AKUOI")
  document.title = title

  /**
   * @desc Mengambil data OPD dan Pangkat
   * Menjalankan lifecycle hook saat komponen pertama kali direndedr
  */

  useEffect(() => {
    const getOpd = async () => {
      const requ = await fetch(`https://sikkap.kotamobagu.go.id/webserv/opd.php`)
      const resu = await requ.json()
      set_opd(resu)
    }
    const getPangkat = async () => {
      const requ = await fetch(`https://sikkap.kotamobagu.go.id/webserv/pangkat.php`)
      const resu = await requ.json()
      set_list_pangkat(resu)
    }
    getOpd()
    getPangkat()
  }, [])

  /**
   * Handles the check username functionality.
   *
   * @param {Event} e - The event object.
   * @return {Promise<void>} Promise that resolves to nothing.
  */
  const handleCheckUsername = async (e) => {
    e.preventDefault();
    set_loading(true)
    set_check_valid(e => { return { ...e, loading: true, status_msg_false: false, status_msg_true: false, status: true } })
    const formData = new FormData()
    // formData.append('username', `${nama_pengguna + subdomainSelect(subdomain)}`)
    formData.append('username', `${nama_pengguna + subdomain}`)
    const requ = await fetch(`${HOST}/periksa`, { method: 'POST', body: formData })
    const resu = await requ.json()
    if (resu.code === 0) {
      setTimeout(() => {
        set_token(resu.token)
        set_check_valid(e => { return { status: false, loading: false, status_msg_netral: false, status_msg_true: true } })
        set_loading(false)
      }, 2000)
    } else {
      setTimeout(() => {
        set_loading(false)
        set_check_valid(e => { return { status: true, loading: false, status_msg_netral: false, status_msg_true: false, status_msg_false: true } })
        error({ msg: resu.message })
      }, 2000)
    }
  }

  /**
    * Method : handleCheckStep1
    * created by : Bobi R. Simbala
    * 
    * Fungsi ini menangani pengecekan untuk langkah 1 dari proses registrasi.
    * Fungsi ini mengatur judul, mengatur loading menjadi true, dan membuat objek FormData.
    * Fungsi ini menambahkan username dan token ke objek FormData.
    * Fungsi ini melakukan permintaan POST ke endpoint API registrasi dengan objek FormData.
    * Jika kode respons adalah 0, fungsi ini mengatur token dan memanggil fungsi handleStep2.
    * Jika tidak, fungsi ini menampilkan pesan kesalahan.
    *
    * @return {Promise<void>} Fungsi ini tidak mengembalikan apapun.
  */

  const handleCheckStep1 = async () => {
    set_title("Langkah 2 | Registrasi | AKUOI")
    set_loading(true)
    const formData = new FormData()
    // formData.append('username', `${nama_pengguna + subdomainSelect(subdomain)}`)
    formData.append('username', `${nama_pengguna + subdomain}`)
    formData.append('token', token)
    const requ = await fetch(`${HOST}/registrasi/step-1`, { method: 'POST', body: formData })
    const resu = await requ.json()
    set_loading(false)
    if (resu.code === 0) {
      set_token(resu.token)
      handleStep2()
    } else {
      error({ msg: resu.message })
    }
  }

  /**
    * Method : handleCheckStep2
    * created by : Bobi R. Simbala
    * 
    * Fungsi ini menangani pengecekan untuk langkah 2 dari proses registrasi.
    * Fungsi ini mengatur judul, mengatur loading menjadi true, dan membuat objek FormData.
    * Fungsi ini menambahkan field username, token, dll ke objek FormData.
    * Fungsi ini melakukan permintaan POST ke endpoint API registrasi/step-2 dengan objek FormData.
    * @param {Event} e - Objek event.
    * @return {Promise<void>} Fungsi ini tidak mengembalikan apapun.
  */

  const handleCheckStep2 = async (e) => {
    e.preventDefault()
    set_loading(true)
    const formData = new FormData()
    // formData.append('username', `${nama_pengguna + subdomainSelect(subdomain)}`)
    formData.append('username', `${nama_pengguna + subdomain}`)
    formData.append('token', token)
    formData.append('nama_depan', nama_depan)
    formData.append('nama_belakang', nama_belakang)
    formData.append('nik', nik)
    formData.append('nip', nip)
    formData.append('unit_kerja', unit_kerja)
    formData.append('jabatan', jabatan)
    formData.append('pangkat', pangkat)
    formData.append('rekomendasi', file_rekom)
    formData.append('ktp', file_ktp)

    const requ = await fetch(`${HOST}/registrasi/step-2`, { method: 'POST', body: formData })
    const resu = await requ.json()
    set_loading(false)
    if (resu.code === 0) {
      set_token(resu.token)
      handleStep3()
    } else {
      error({ msg: resu.message })
    }
    set_title("Langkah 3 | Registrasi | AKUOI")
  }

  /**
   * Method : handleCheckStep3
   * created by : Bobi R. Simbala
   *
   * Fungsi ini bertanggung jawab untuk menangani aksi check step 3 ketika pengguna mengirimkan formulir.
   * Fungsi ini mengatur judul menjadi "Ringkasan | Registrasi | AKUOI", mengatur status loading menjadi true,
   * membuat objek FormData baru dan menambahkan data yang diperlukan ke dalamnya.
   * Kemudian, fungsi ini melakukan permintaan POST ke server dengan data formulir,
   * dan menangani respons sesuai dengan kondisinya.
   * Jika kode respons adalah 0, fungsi ini mengatur token dan memanggil fungsi handleStepDetail.
   * Jika tidak, fungsi ini menampilkan pesan error dengan pesan respons.
   *
   * @param {Event} e - Objek event.
   * @return {Promise<void>} - Fungsi ini tidak mengembalikan apapun.
  */
  const handleCheckStep3 = async (e) => {
    e.preventDefault()
    set_title("Ringkasan | Registrasi | AKUOI")
    set_loading(true)
    const formData = new FormData()
    // formData.append('username', `${nama_pengguna + subdomainSelect(subdomain)}`)
    formData.append('username', `${nama_pengguna + subdomain}`)
    formData.append('token', token)
    formData.append('notelp', no_telp)
    formData.append('pertanyaan', pertanyaan_keamanan)
    formData.append('jawaban', pertanyaan_jawaban)
    const requ = await fetch(`${HOST}/registrasi/step-3`, { method: 'POST', body: formData })
    const resu = await requ.json()
    set_loading(false)
    if (resu.code === 0) {
      set_token(resu.token)
      handleStepDetail()
    } else {
      error({ msg: resu.message })
    }
  }

  /**
   * Method : handleCheckDetail
   * created by : Bobi R. Simbala
   *
   * Fungsi ini bertanggung jawab untuk mengatur judul, status loading, dan melakukan
   * permintaan asinkron ke server untuk memeriksa detail. Fungsi ini menambahkan data yang
   * diperlukan ke objek FormData dan mengirim permintaan POST ke endpoint yang ditentukan.
   * Jika permintaan berhasil, fungsi ini akan memperbarui state dengan data yang diterima
   * dan melakukan beberapa tindakan tambahan. Jika terjadi kesalahan selama permintaan atau
   * pemrosesan respons, fungsi ini akan menampilkan pesan error.
   *
   * @return {Promise<void>} Fungsi ini tidak mengembalikan nilai apapun.
  */
  const handleCheckDetail = async () => {
    set_title("Selesai | Registrasi | AKUOI")
    set_loading(true)
    const formData = new FormData()
    // formData.append('username', `${nama_pengguna + subdomainSelect(subdomain)}`)
    formData.append('username', `${nama_pengguna + subdomain}`)
    formData.append('token', token)
    const requ = await fetch(`${HOST}/registrasi/finish`, { method: 'POST', body: formData })
    try {
      const resu = await requ.json()
      set_loading(false)
      if (resu.code === 0) {
        set_data_finish(resu)
        set_link(resu.link)
        handleStepFinish()
      } else {
        error({ msg: requ.message })
      }
    } catch (error) {
      error({ msg: `Sedang ada maintenance server:${error.message}` })
    }

  }


  /**
   * Method : handleStep1
   * created by : Bobi R. Simbala
   * Mengganti tampilan langkah 1 dan memperbarui tampilan langkah 2.
   *
   * @return {undefined} Tidak ada nilai kembalian
  */
  const handleStep1 = () => {
    set_view_step1(e => !e)
    set_view_step2(false)
  }

  /**
   * Method : handleStep2
   * created by : Bobi R. Simbala
   *
   * Fungsi ini memeriksa apakah variabel `nama_pengguna` (username) dan `subdomain` kosong. 
   * Jika salah satunya kosong, maka akan mengembalikan pesan error yang mengindikasikan bahwa kolom "Nama Pengguna" dan "Subdomain" harus diisi.
   * Jika kedua variabel `nama_pengguna` dan `subdomain` tidak kosong, fungsi ini akan mengupdate state dengan mengubah nilai `view_step1` menjadi `false`, `view_step2` menjadi kebalikan dari nilai sebelumnya, dan `view_step3` menjadi `false`.
   *
   * @return {void} Fungsi ini tidak mengembalikan nilai.
 */
  const handleStep2 = () => {
    if (nama_pengguna === "" || subdomain === "") {
      return error({ msg: 'Pastikan Nama Pengguna dan Subdomain telah diisi' })
    }
    set_view_step1(false)
    set_view_step2(e => !e)
    set_view_step3(false)
  }

  /**
   * Method : handleStep3
   * created by : Bobi R. Simbala
   *
   * Fungsi ini mengupdate state dengan mengubah nilai `view_step2` menjadi `false`, `view_step3` menjadi kebalikan dari nilai sebelumnya, dan `view_detail` menjadi `false`.
   *
   * @return {void} Fungsi ini tidak mengembalikan nilai.
 */

  const handleStep3 = () => {
    set_view_step2(false)
    set_view_step3(e => !e)
    set_view_detail(false)
  }

  /**
   * Method : handleStepDetail
   * created by : Bobi R. Simbala
   *
   * Fungsi ini mengupdate state dengan mengubah nilai `view_step3` menjadi `false`, `view_detail` menjadi kebalikan dari nilai sebelumnya, dan `view_finish` menjadi `false`.
   *
   * @return {void} Fungsi ini tidak mengembalikan nilai.
 */
  const handleStepDetail = () => {
    set_view_step3(false)
    set_view_detail(e => !e)
    set_view_finish(false)
  }

  /**
   * Method : handleStepFinish
   * created by : Bobi R. Simbala
   *
   * Fungsi ini mengupdate state dengan mengatur `view_detail` menjadi `false` dan `view_finish` menjadi kebalikan dari nilai sebelumnya.
   *
   * @return {void} Fungsi ini tidak mengembalikan nilai.
 */
  const handleStepFinish = () => {
    set_view_detail(false)
    set_view_finish(e => !e)
  }

  /**
   * Method : handleViewStepByStep
   * created by : Bobi R. Simbala
   *
   * Fungsi ini memeriksa nilai `view_step1`, `view_step2`, `view_step3`, `view_detail`, dan `view_finish` untuk menentukan langkah/komponen mana yang harus dikembalikan.
   *
   * Jika `view_step1` bernilai true, maka akan mengembalikan komponen Step_1.
   * Jika `view_step2` bernilai true, maka akan mengembalikan komponen Step_2.
   * Jika `view_step3` bernilai true, maka akan mengembalikan komponen Step_3.
   * Jika `view_detail` bernilai true, maka akan mengembalikan komponen Step_detail.
   * Jika `view_finish` bernilai true, maka akan mengembalikan komponen Step_finish.
   *
   * @return {Component} Komponen yang sesuai dengan langkah saat ini.
 */
  const handleViewStepByStep = () => {
    if (view_step1) {
      return Step_1
    }
    if (view_step2) {
      return Step_2
    }
    if (view_step3) {
      return Step_3
    }
    if (view_detail) {
      return Step_detail
    }
    if (view_finish) {
      return Step_finish
    }
  }

  /**
   * Method : subdomainSelect
   * created by : Bobi R. Simbala
   *
   * Fungsi ini mengambil nilai `subdomain` sebagai input dan mengembalikan alamat email subdomain yang sesuai berdasarkan value yang dipilih.
   *
   * @param {string} subdomain - Nilai subdomain.
   * @return {string} Alamat email subdomain.
 */
  const subdomainSelect = (subdomain) => {
    switch (subdomain) {
      case 'asn':
        return '@asn.kotamobagu.go.id'
      case 'umum':
        return '@umum.kotamobagu.go.id'
      case 'dprd':
        return '@dprd.kotamobagu.go.id'
      case 'rsud':
        return '@rsud.kotamobagu.go.id'
      default:
        break;
    }
  }

  /**
   * Method : handleGetField
   * created by : Bobi R. Simbala
   *
   * Fungsi ini mengambil objek `event` sebagai input dan melakukan tindakan yang berbeda berdasarkan properti `name` dari elemen target.
   * Fungsi ini mengambil properti `name` dan `value` dari elemen target saat ini dan mengassign-nya ke variabel yang sesuai.
   * Jika `name` adalah 'surat_rekom' atau 'ktp', maka fungsi ini mengambil file yang dipilih dari elemen input.
   * Kemudian, menggunakan `switch case` untuk menentukan fungsi setter state mana yang harus dipanggil berdasarkan `name`.
   *
   * @param {object} event - Objek event.
   * @return {void} Fungsi ini tidak mengembalikan nilai.
 */
  const handleGetField = (event) => {
    const current = event.target;
    const name = current.name;
    const value = current.value;
    const file = name === 'surat_rekom' || name === 'ktp' ? current.files[0] : ''
    switch (name) {
      case 'nama_pengguna':
        return set_nama_pengguna(value)
      case 'subdomain':
        return set_subdomain(subdomainSelect(value))
      case 'nama_depan':
        return set_nama_depan(value)
      case 'nama_belakang':
        return set_nama_belakang(value)
      case 'nip':
        return set_nip(value)
      case 'nik':
        return set_nik(value)
      case 'unit_kerja':
        return set_unit_kerja(value)
      case 'jabatan':
        return set_jabatan(value)
      case 'pangkat':
        return set_pangkat(value)
      case 'surat_rekom':
        return set_file_rekom(file)
      case 'ktp':
        return set_file_ktp(file)
      case 'no_telp':
        return set_no_telp(value)
      case 'pertanyaan_keamanan':
        return set_pertanyaan_keamanan(value)
      case 'pertanyaan_jawaban':
        return set_pertanyaan_jawaban(value)
      default:
        break;
    }
  }

  const Step_1 = (
    <>
      <div className="card">
        <div className="card-body">
          <h1 className='p-0 m-0'>Registrasi - Langkah 1</h1>
          <hr style={{ borderTop: '3px solid #4c56c0' }} />
          <form onSubmit={handleCheckUsername}>
            <div className="form-group">
              <label htmlFor="nama_pengguna">Nama Pengguna</label>
              <input type="text" autoComplete={"off"} id="nama_pengguna" name="nama_pengguna" className="form-control" value={nama_pengguna} onChange={handleGetField} required />
            </div>
            <label htmlFor="nama_pengguna">Pilih Subdomain</label>
            <div className="custom-control custom-radio">
              <input type="radio" id="customRadio1" name="subdomain" value={"asn"} className="custom-control-input" defaultChecked="checked"  onChange={handleGetField}/>
              <label className="custom-control-label" htmlFor="customRadio1">@asn.kotamobagu.go.id</label>
            </div>
            <div className="custom-control custom-radio">
              <input type="radio" id="customRadio2" name="subdomain" value={"rsud"} className="custom-control-input" onChange={handleGetField} />
              <label className="custom-control-label" htmlFor="customRadio2">@rsud.kotamobagu.go.id</label>
            </div>
            {/* <div className="custom-control custom-radio">
              <input type="radio" id="customRadio2" name="subdomain" value={"umum"} className="custom-control-input" onChange={handleGetField} />
              <label className="custom-control-label" htmlFor="customRadio2">@umum.kotamobagu.go.id</label>
            </div>
            <div className="custom-control custom-radio">
              <input type="radio" id="customRadio3" name="subdomain" value={"dprd"} className="custom-control-input" onChange={handleGetField} />
              <label className="custom-control-label" htmlFor="customRadio3">@dprd.kotamobagu.go.id</label>
            </div> */}
          </form>
        </div>
        {loading && <BarLoader size={20} color={"#4c04b8"} className="w-100" />}
        <div className="card-footer d-grid gap-2" style={{ backgroundColor: '#4c56c0' }}>
          <div className='register__btn-register-group'>
            <button type='submit' className="btn btn-secondary" onClick={handleCheckUsername} disabled={check_valid.loading}>{check_valid.loading ? 'Mohon tunggu...' : 'Cek Ketersediaan'}</button>
            <button type='button' onClick={handleCheckStep1} className="btn btn-success register__btn-register-next" disabled={check_valid.status}>Selanjutnya</button>
            {check_valid.status_msg_netral && ""}
            {check_valid.status_msg_true && <span className='text-center'><BsCheck className='text-center' size={34} style={{ color: "#5eff7c" }} /></span>}
            {check_valid.status_msg_false && <span className='text-center'><BsX className='text-center' size={34} style={{ color: "#f75e7d" }} /></span>}
          </div>
        </div>
      </div>
    </>
  )

  const Step_2 = (
    <>
      <div className="card">
        <form className='mt-3' onSubmit={handleCheckStep2}>
          <div className="card-body">
            <h1>Registrasi - Langkah 2</h1>
            {/* <p>{nama_pengguna}{subdomainSelect(subdomain)}</p> */}
            <strong>Nama Pengguna</strong>
            <p style={{ fontFamily: 'monospace' }}>{nama_pengguna + subdomain}</p>
            <hr style={{ borderTop: '3px solid #4c56c0' }} />

            <div className="form-group">
              <label htmlFor="nama_depan">Nama Depan</label>
              <input type="text" autoComplete={"off"} id="nama_depan" name="nama_depan" className="form-control" value={nama_depan} onChange={handleGetField} required />
            </div>

            <div className="form-group">
              <label htmlFor="nama_belakang">Nama Belakang</label>
              <input type="text" autoComplete={"off"} id="nama_belakang" name="nama_belakang" className="form-control" value={nama_belakang} onChange={handleGetField} required />
            </div>

            <div className="form-group">
              <label htmlFor="nik">NIK</label>
              <input type="text" autoComplete={"off"} id="nik" name="nik" className="form-control" value={nik} onChange={handleGetField} required />
            </div>
            {
              subdomain === "@asn.kotamobagu.go.id"
                ?
                <div className="form-group">
                  <label htmlFor="nip">NIP</label>
                  <input type="text" autoComplete={"off"} id="nip" name="nip" className="form-control" value={nip} onChange={handleGetField} required />
                </div>
                :
                ''
            }


            <div className="form-group">
              <label htmlFor="unit_kerja">Unit Kerja</label>
              <select className='form-control' id="unit_kerja" name="unit_kerja" value={unit_kerja} onChange={handleGetField} required>
                <option>--Pilih--</option>
                {
                  opd.map(v => { return <option value={v} key={v}>{v}</option> })
                }
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="jabatan">Jabatan</label>
              <input type="text" autoComplete={"off"} id="jabatan" name="jabatan" className="form-control" value={jabatan} onChange={handleGetField} required />
            </div>

            <div className="form-group">
              <label htmlFor="pangkat">Pangkat</label>
              <select className="form-control" id="pangkat" name="pangkat" value={pangkat} onChange={handleGetField} required>
                <option>-Pilih-</option>
                {
                  list_pangkat.map(v => { return <option key={v}>{v}</option> })
                }
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="surat_rekom">Surat Rekomendasi</label>
              <div className="custom-file">
                <input type="file" className="custom-file-input" id="surat_rekom" name="surat_rekom" onChange={handleGetField} required />
                <label className="custom-file-label" htmlFor="surat_rekom">{file_rekom ? `${file_rekom.name} | ${bytesToSize(file_rekom.size)}` : 'Telusuri'}</label>
              </div>
              <small>Unduh contoh rekomendasi <a className='text-primary' href='https://s.id/rekomendasi-email-kk' rel='noreferrer' target={"_blank"}>di sini</a></small>
            </div>

            <div className="form-group">
              <label htmlFor="ktp">KTP/KARPEG/SWAFOTO</label>
              <div className="custom-file">
                <input type="file" className="custom-file-input" id="ktp" name="ktp" onChange={handleGetField} />
                <label className="custom-file-label" htmlFor="ktp">{file_ktp ? `${file_ktp.name} | ${bytesToSize(file_ktp.size)}` : 'Telusuri'}</label>
              </div>
              <small>Swafoto calon pengguna dengan memegang surat rekomendasi yang sudah dicap dan ditandatangani oleh atasan </small>
            </div>

          </div>
          {loading && <BarLoader size={20} color={"#4c04b8"} className="w-100" />}
          <div className="card-footer register__btn-register-group d-grid gap-2" style={{ backgroundColor: '#4c56c0' }}>
            <button className="btn btn-secondary" onClick={handleStep1}>Sebelumnya</button>
            <button type='submit' className="btn btn-success register__btn-register-next" disabled={loading}>{loading ? 'Mohon tunggu..' : 'Selanjutnya'}</button>
          </div>
        </form>
      </div>
    </>
  )

  const Step_3 = (
    <>
      <div className="card">
        <form className='mt-3' onSubmit={handleCheckStep3}>
          <div className="card-body">
            <h1>Registrasi - Langkah 3</h1>
            {/* <p>{nama_pengguna}{subdomainSelect(subdomain)}</p> */}
            <strong>Nama Pengguna</strong>
            <p style={{ fontFamily: 'monospace' }}>{nama_pengguna + subdomain}</p>
            <hr style={{ borderTop: '3px solid #4c56c0' }} />

            <div className="form-group">
              <label htmlFor="no_telp">No. Telp</label>
              <input type="text" autoComplete={"off"} id="no_telp" name="no_telp" className="form-control" value={no_telp} onChange={handleGetField} />
            </div>

            <div className="form-group">
              <label htmlFor="pertanyaan_keamanan">Pertanyaan Keamanan</label>
              <input type="text" autoComplete={"off"} id="pertanyaan_keamanan" name="pertanyaan_keamanan" value={pertanyaan_keamanan} onChange={handleGetField} className="form-control" />
            </div>

            <div className="form-group">
              <label htmlFor="pertanyaan_jawaban">Jawaban</label>
              <input type="text" autoComplete={"off"} id="pertanyaan_jawaban" name="pertanyaan_jawaban" value={pertanyaan_jawaban} onChange={handleGetField} className="form-control" />
            </div>
          </div>
          {loading && <BarLoader size={20} color={"#4c04b8"} className="w-100" />}
          <div className="card-footer register__btn-register-group d-grid gap-2" style={{ backgroundColor: '#4c56c0' }}>
            <button className="btn btn-secondary" onClick={handleStep2}>Sebelumnya</button>
            <button type='submit' className="btn btn-success register__btn-register-next" disabled={loading}>{loading ? 'Mohon tunggu..' : 'Selanjutnya'}</button>
          </div>
        </form>
      </div>
    </>
  )

  const Step_detail = (
    <div className="card">
      <div className="card-body">
        <h1>Registrasi - Ringkasan</h1>
        {/* <p>{nama_pengguna}{subdomainSelect(subdomain)}</p> */}
        <strong>Nama Pengguna</strong>
        <p style={{ fontFamily: 'monospace' }}>{nama_pengguna + subdomain}</p>
        <hr style={{ borderTop: '3px solid #4c56c0' }} />
        <div style={{ borderBottom: '0.5px dotted #666', marginBottom: '2%' }}>
          <h6>Nama Pengguna</h6>
          <span><strong>{nama_pengguna}</strong></span>
        </div>

        <div style={{ borderBottom: '0.5px dotted #666', marginBottom: '2%' }}>
          <h6>Nama Depan</h6>
          <span><strong>{nama_depan}</strong></span>
        </div>

        <div style={{ borderBottom: '0.5px dotted #666', marginBottom: '2%' }}>
          <h6>Nama Belakang</h6>
          <span><strong>{nama_belakang}</strong></span>
        </div>
        <div style={{ borderBottom: '0.5px dotted #666', marginBottom: '2%' }}>
          <h6>NIK</h6>
          <span><strong>{nik}</strong></span>
        </div>
        <div style={{ borderBottom: '0.5px dotted #666', marginBottom: '2%' }}>
          <h6>NIP</h6>
          <span><strong>{nip}</strong></span>
        </div>
        <div style={{ borderBottom: '0.5px dotted #666', marginBottom: '2%' }}>
          <h6>Unit Kerja</h6>
          <span><strong>{unit_kerja}</strong></span>
        </div>
        <div style={{ borderBottom: '0.5px dotted #666', marginBottom: '2%' }}>
          <h6>Jabatan</h6>
          <span><strong>{jabatan}</strong></span>
        </div>
        <div style={{ borderBottom: '0.5px dotted #666', marginBottom: '2%' }}>
          <h6>Pangkat</h6>
          <span><strong>{pangkat}</strong></span>
        </div>
        <div style={{ borderBottom: '0.5px dotted #666', marginBottom: '2%' }}>
          <h6>Pertanyaan Keamanan</h6>
          <span><strong>{pertanyaan_keamanan}</strong></span>
        </div>
        <div style={{ borderBottom: '0.5px dotted #666', marginBottom: '2%' }}>
          <h6>Jawaban</h6>
          <span><strong>{pertanyaan_jawaban}</strong></span>
        </div>
        <p>Dengan menekan tombol selesai, saya setuju dengan syarat dan ketentuan yang berlau</p>
      </div>
      {loading && <BarLoader size={20} color={"#4c04b8"} className="w-100" />}
      <div className="card-footer register__btn-register-group d-grid gap-2" style={{ backgroundColor: '#4c56c0' }}>
        <button className="btn btn-secondary" onClick={handleStep3} disabled={loading}>Sebelumnya</button>
        <button className="btn btn-success register__btn-register-next" onClick={handleCheckDetail} disabled={loading}>{loading ? 'Mohon tunggu..' : 'Selesai'}</button>
      </div>
    </div>
  )

  const Step_finish = (
    <div className="card">
      <div className="card-body">
        <h1>Registrasi - Selesai</h1>
        <hr style={{ borderTop: '3px solid #4c56c0' }} />
        <p className='p-0 m-0'>Pendaftaran akun:</p>
        {/* <span className='font-weight-bold'>{nama_pengguna}{subdomainSelect(subdomain)}</span> telah selesai. */}
        <span className='font-weight-bold'>{nama_pengguna}{subdomain}</span> telah selesai.
        <p className='m-0 p-0 mb-2'>Proses selanjutnya mengunggu verifikasi persetujuan dan aktivasi.</p>
        <div className='alert alert-warning' style={{ border: "2px solid salmon" }}>
          <p className='m-0 p-0'>Mohon untuk mengingat/mencatat token pendaftaran anda guna keperluan aktivasi.</p>
          <p className='m-0 p-0'>Token pendaftaran Anda : <span className='font-weight-bold'>{data_finish.token}</span></p>
        </div>

        {link ?
          <>
            <div className='alert alert-primary'>
              <p>Atau anda bisa download bukti pendafraran pada tautan dibawah</p>
              <a href={`${HOST}/registrasi/bukti?sid=${link}`} className='btn btn-info btn-lg' target={"_blank"} rel="noreferrer">
                <BsDownload /> Unduh Bukti Pendaftaran</a>
              <h6 className='p-0 m-0 mt-3'><BsInfoCircle /> Catatan:</h6>
              <ul style={{ fontSize: '14px' }}>
                <li>Waktu pengunduhan bukti pendaftaran hanya berlaku selama <strong>6 Menit</strong></li>
                <li>Batas pengunduhan bukti pendaftaran maksimal <strong>3 kali</strong></li>
              </ul>
            </div>
          </>
          :
          ''}
      </div>
      <div className="card-footer d-grid gap-2" style={{ backgroundColor: '#4c56c0' }}>
        <button className="btn btn-secondary" onClick={() => direct('/')}>Kembali Ke Halaman Login</button>
      </div>
    </div>
  )

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-8 mx-auto my-5 login-desk">
          <h1 className='text-center' style={{ color: '#4c56c0', fontWeight: 'bold' }}>AKUOI <small className='text-muted' style={{ fontSize: '50%' }}>v1.0</small></h1>
          {handleViewStepByStep()}
        </div>
      </div>
    </div>
  )
}

export default Register