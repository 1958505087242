import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { BsCheck, BsArrowLeftShort } from 'react-icons/bs'
import { BarLoader } from 'react-spinners';
import '../assets/style.css'
import { HostContext } from '../context';
import { error, success } from '../util';

/**
  * Component : Cek Akun
  * created by : Bobi R. Simbala
  * 
*/
const Cek_akun = () => {
  document.title = "Cek Akun Pengguna"
  const direct = useNavigate()
  const { HOST } = useContext(HostContext)
  const [nama_pengguna, set_nama_pengguna] = useState("")
  const [nik, set_nik] = useState("")
  const [no_telp, set_notelp] = useState("")
  const [token_pendaftaran, set_token_pendaftaran] = useState("")
  const [loading, set_loading] = useState(false)

  /**
    * Method : handleCekSubmit
    * created by : Bobi R. Simbala
    *
    * @param {Event} event - Objek peristiwa submit.
    * @return {Promise<void>} Promise yang akan diselesaikan ketika fungsi selesai dieksekusi.
  */
  const handleCekSubmit = async (event) => {
    event.preventDefault()
    set_loading(true)
    const formData = new FormData()
    formData.append('username', nama_pengguna)
    formData.append('nik', nik)
    formData.append('notelp', no_telp)
    formData.append('token', token_pendaftaran)
    const requ = await fetch(`${HOST}/lacak-akun`, { method: 'POST', body: formData })
    try {
      const resu = await requ.json()
      set_loading(false)
      if (resu.code === 0) {
        if (resu.setup) {
          direct('/atur-akun', { state: { token: resu.token, username: nama_pengguna } })
        } else {
          success({ msg: resu.message })
        }
      } else {
        error({ msg: resu.message })
      }
    } catch (err) {
      error({ msg: 'Disini ' + err.message })
    }
  }
  return (
    <div className='container'>
      <div className="row">
        <div className='col-md-6 mx-auto my-5 login-desk'>
          <h1 className='text-center' style={{ color: '#4c56c0', fontWeight: 'bold' }}>AKUOI <small className='text-muted' style={{ fontSize: '50%' }}>v1.0</small></h1>
          <div className="card">
            <form onSubmit={handleCekSubmit}>
              <div className="card-body">
                <h2>Lacak Pendaftaran</h2>
                <hr style={{ borderTop: '3px solid #4c56c0' }} />
                <div className="form-group">
                  <label htmlFor="nama_pengguna">Nama Pengguna</label>
                  <input type="text" autoComplete='off' id="nama_pengguna" name="nama_pengguna" className="form-control" value={nama_pengguna} onChange={e => set_nama_pengguna(e.target.value)} required />
                </div>
                <div className="form-group">
                  <label htmlFor="nik">NIK</label>
                  <input type="text" autoComplete='off' id="nik" name="nik" className="form-control" value={nik} onChange={e => set_nik(e.target.value)} required />
                </div>
                <div className="form-group">
                  <label htmlFor="no_telp">No. Telp</label>
                  <input type="text" autoComplete='off' id="no_telp" name="no_telp" className="form-control" value={no_telp} onChange={e => set_notelp(e.target.value)} required />
                </div>
                <div className="form-group">
                  <label htmlFor="token_pendaftaran">Token Pendaftaran</label>
                  <input type="text" autoComplete='off' id="token_pendaftaran" name="token_pendaftaran" className="form-control" value={token_pendaftaran} onChange={e => set_token_pendaftaran(e.target.value)} required />
                </div>
                <div className="form-group">
                  <small> Lupa token? <Link to='/lupa-token' >Klik Disini</Link></small>
                </div>
              </div>
              {loading && <BarLoader size={20} color={"#4c04b8"} className="w-100" />}
              <div className="card-footer cek_akun__btn-check-group d-grid gap-2r" style={{ backgroundColor: '#4c56c0' }}>
                <button type='button' className='btn btn-secondary d-flex' onClick={() => direct('/')}><BsArrowLeftShort size={24} /> Kembali Ke Login</button>
                <button type='submit' className='btn btn-success cek_akun__btn-check-submit d-flex' disabled={loading}><BsCheck size={24} /> {loading ? 'Mohon tunggu...' : 'Cek Akun'}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cek_akun