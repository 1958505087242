import React, { useState } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { BarLoader } from 'react-spinners'
import { AuthContext, HostContext } from '../context'
import { success, error, info } from '../util'
import { FaRegQuestionCircle } from 'react-icons/fa'
import { Tooltip } from 'react-tooltip'
import ReactDOMServer from 'react-dom/server';

/**
  * Component : Login
  * created by : Bobi R. Simbala
  * 
*/
const Login = () => {
  document.title = "Login Pengguna | AKUOI"
  const { HOST } = useContext(HostContext)
  const { DISPATCH_LOGIN } = useContext(AuthContext)
  const direct = useNavigate()
  const [username, set_username] = useState("")
  const [password, set_password] = useState("")
  const [loading, set_loading] = useState(false)

  /**
    * Method : handleCheckStep1
    * created by : Bobi R. Simbala
    *
    * Fungsi ini bertanggung jawab untuk menangani pengiriman formulir saat pengguna mencoba untuk login. Fungsi ini melakukan langkah-langkah berikut:
    * 1. Mencegah perilaku pengiriman formulir default.
    * 2. Mengatur status loading menjadi true.
    * 3. Membuat instansi baru dari FormData dan menambahkan username dan password ke dalamnya.
    * 4. Mengirimkan permintaan POST ke endpoint login dengan data formulir.
    * 5. Mengurai respons sebagai JSON.
    * 6. Jika kode respons adalah 0, melakukan dispatch terhadap aksi login dengan session dan data dari respons, mengarahkan ke halaman dashboard, menampilkan pesan sukses, dan mencatat status login.
    * 7. Jika kode respons bukan 0, menampilkan pesan error.
    * 8. Mengatur status loading menjadi false.
    * 9. Jika terjadi kesalahan selama proses, menampilkan pesan error dan mengatur status loading menjadi false.
    *
    * @param {Event} event - Objek event yang mewakili pengiriman formulir.
    * @return {Promise} Sebuah promise yang menghasilkan void.
  */
  const handleLoginSubmit = async (event) => {
    event.preventDefault()
    set_loading(true)
    const formData = new FormData()
    formData.append('username', username)
    formData.append('sandi', password)
    const requ = await fetch(`${HOST}/login`, { method: 'POST', body: formData })
    try {
      const resu = await requ.json()
      if (resu.code === 0) {
        DISPATCH_LOGIN({ type: 'LOGIN', session: resu.session, ...resu.data })
        direct('/dashboard')
        success({ msg: resu.message })
        console.log('LOGIN_STATE', resu)
      } else {
        error({ msg: resu.message })
      }
      set_loading(false)
    } catch (err) {
      error({ mag: err.message })
      set_loading(false)
    }

  }
  return (
    <>
      <div id="page-container">
        <main id="main-container ">
          <div className="bg-image ">
            <div className="hero-statics">
              <div className="content">
                <h1 className='text-center' style={{ color: '#4c56c0', fontWeight: 'bold' }}>AKUOI <small className='text-muted' style={{ fontSize: '50%' }}>v1.0</small></h1>
                <div className="row justify-content-center">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-3">
                    <div className="block block-themed block-fx-shadow mb-0">
                      <div className="block-header" style={{ backgroundColor: "#4c56c0" }}>
                        <h3 className="block-title text-center" style={{ fontSize: 24 }}>Masuk Pengguna</h3>
                      </div>
                      <div className="block-content mt-auto">
                        <div className="p-sm-12 px-lg-12 py-lg-3">
                          <Tooltip anchorId="tooltip-pengguna" />
                          <Tooltip anchorId="tooltip-sandi" style={{ zIndex: 999 }} />
                          <form onSubmit={handleLoginSubmit}>
                            <div className="form-row">
                              <label htmlFor="">Nama Pengguna <FaRegQuestionCircle size={12} id="tooltip-pengguna" data-tooltip-html={ReactDOMServer.renderToString(
                                <p>
                                  <strong>Catatan:</strong><br />
                                  * Nama pengguna hendaknya memiliki satu atau lebih bagian yang berasal dari nama lengkap pribadi <br />
                                  * Dilarang memasukkan unsur SARA atau yang dapat mengundang polemik <br />
                                  * Nama pengguna bisa juga merupakan NIP atau NIK atau ID lain yang berupa angka
                                </p>
                              )} /></label>
                              <div className="input-group mb-2 border-primary border">
                                <div className="input-group-prepend">
                                  <span className="input-group-text input-group-text-alt" id="basic-addon1">
                                    <i className="far fa-user"></i>
                                  </span>
                                </div>
                                <input type="text" className="form-control form-control-alt" name="username" aria-label="Username" aria-describedby="basic-addon1" style={{ height: '40px' }} value={username} onChange={e => set_username(e.target.value)} />
                              </div>
                            </div>
                            <div className="form-row">
                              <label htmlFor="">Kata Sandi <FaRegQuestionCircle size={12} id="tooltip-sandi" data-tooltip-html={ReactDOMServer.renderToString(
                                <p>
                                  <strong>Catatan:</strong><br />
                                  Sebaiknya mengandung karakter huruf, angka, dan karakter spesial
                                </p>
                              )} /></label>
                              <div className="input-group mb-2 border-primary border">
                                <div className="input-group-prepend">
                                  <span className="input-group-text input-group-text-alt" id="basic-addon1">
                                    <i className="fas fa-lock"></i>
                                  </span>
                                </div>
                                <input type="password" className="form-control form-control-alt" name="password" aria-label="Username" aria-describedby="basic-addon1" style={{ height: '40px' }} value={password} onChange={e => set_password(e.target.value)} />
                              </div>
                            </div>
                            <button type='submit' className="btn btn-small btn-warning my-3 w-100" style={{ height: '40px', borderRadius: 0 }} disabled={loading}>{loading ? 'Mohon tunggu...' : 'Masuk'}</button>
                            <div className="d-flex flex-column">
                              <a className="m-1 BRS_help_button" href onClick={() => direct('/register')} title="Register">
                                <i className="fa fa-user-plus"></i> Registrasi Pengguna
                              </a>
                              <a className="m-1 BRS_help_button" href onClick={() => direct('/cek-akun')} title="Cek Pendaftaran">
                                <i className="fa fa-user-check"></i> Cek Pendaftaran
                              </a>
                              <a className="m-1 BRS_help_button" href onClick={() => direct('/reset-sandi')} title="Lupa Kata Sandi.?">
                                <i className="fa fa-user-lock"></i> Lupa Kata Sandi
                              </a>
                            </div>
                          </form>
                        </div>
                      </div>
                      {loading && <BarLoader size={42} className="w-100" color={"#4c04b8"} />}
                      <div style={{ width: '100%', height: '10px', backgroundColor: '#5c80d1' }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

export default Login;