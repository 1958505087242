import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context'

const Page_dashboard = () => {
  document.title = "Beranda | AKUOI"
  const direct = useNavigate()
  const { STATE_LOGIN } = useContext(AuthContext)
  if (!STATE_LOGIN.is_login) {
    return direct('/')
  }
  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">

          <div class="block">
            <div className="block-header">
              <h3 className='block-title'>Beranda</h3>
            </div>
            <div className="block-content">
              <div className='mb-5 border border-primary p-2' >
                <h3 className='m-0 p-0'>Status Pengguna</h3>
                <span>{STATE_LOGIN.grup}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page_dashboard