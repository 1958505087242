import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsCheck, BsArrowLeftShort } from 'react-icons/bs'
import { BarLoader } from 'react-spinners';
import '../assets/style.css'
import { HostContext } from '../context';
import { error, success } from '../util';
import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
const Lupa_token = () => {
  document.title = "Cek Akun Pengguna"
  const direct = useNavigate()
  const { HOST } = useContext(HostContext)
  const [loading, set_loading] = useState(false)
  const [view_page, set_view_page] = useState(1)


  const [nama_pengguna, set_nama_pengguna] = useState("")
  const [nik, set_nik] = useState("")
  const [data, set_data] = useState({})
  const [token, set_token] = useState("")
  const [jawaban, set_jawaban] = useState("")

  const handleCekToken = async (event) => {
    event.preventDefault()
    set_loading(true)
    const formData = new FormData()
    formData.append('username', nama_pengguna)
    try {
      const request = await fetch(`${HOST}/reset/token`, { method: 'POST', body: formData })
      const response = await request.json()
      if (request.ok) {
        if (response.code === 0) {
          console.log('Disini Page1:', response)
          set_data(response.data)
          set_token(response.token)
          set_view_page(2)
          set_loading(false)
        } else {
          error({ msg: response.message })
          set_loading(false)
        }
      } else {
        throw new Error(response.message)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading(false)
    }
  }

  const handleCekTokenProses = async (event) => {
    event.preventDefault()
    set_loading(true)
    const formData = new FormData()
    formData.append('username', nama_pengguna)
    formData.append('nik', nik)
    formData.append('token', token)
    formData.append('jawaban', jawaban)
    try {
      const request = await fetch(`${HOST}/reset/token/proses`, { method: 'POST', body: formData })
      const result = await request.json()
      if (request.ok) {
        if (result.code === 0) {
          success({ msg: result.message })
          set_token(result.token)
          set_view_page(3)
          set_loading(false)
        } else {
          error({ msg: result.message })
          set_loading(false)
        }
      } else {
        throw new Error(result.message)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading(false)
    }
  }

  const ViewPage1 = (
    <div className='container'>
      <div className="row">
        <div className='col-md-6 mx-auto my-5 login-desk'>
          <h1 className='text-center' style={{ color: '#4c56c0', fontWeight: 'bold' }}>AKUOI <small className='text-muted' style={{ fontSize: '50%' }}>v1.0</small></h1>
          <div className="card">
            <form onSubmit={handleCekToken}>
              <div className="card-body">
                <h2>Lupa Token</h2>
                <hr style={{ borderTop: '3px solid #4c56c0' }} />
                <div className="form-group">
                  <label htmlFor="nama_pengguna">Nama Pengguna</label>
                  <input type="text" autoComplete='off' id="nama_pengguna" name="nama_pengguna" className="form-control" value={nama_pengguna} onChange={e => set_nama_pengguna(e.target.value)} required />
                </div>
              </div>
              {loading && <BarLoader size={20} color={"#4c04b8"} className="w-100" />}
              <div className="card-footer cek_akun__btn-check-group d-grid gap-2r" style={{ backgroundColor: '#4c56c0' }}>
                <button type='button' className='btn btn-secondary d-flex' onClick={() => direct('/')}><BsArrowLeftShort size={24} /> Kembali Ke Login</button>
                <button type='submit' className='btn btn-success cek_akun__btn-check-submit d-flex' disabled={loading}><BsCheck size={24} /> {loading ? 'Mohon tunggu...' : 'Selanjutnya'}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )

  const ViewPage2 = (
    <div className='container'>
      <div className="row">
        <div className='col-md-6 mx-auto my-5 login-desk'>
          <h1 className='text-center' style={{ color: '#4c56c0', fontWeight: 'bold' }}>AKUOI <small className='text-muted' style={{ fontSize: '50%' }}>v1.0</small></h1>
          <div className="card">
            <form onSubmit={handleCekTokenProses}>
              <div className="card-body">

                <h2>Lupa Token</h2>
                <hr style={{ borderTop: '3px solid #4c56c0' }} />
                <h3>Lengkapi Form Pertanyaan Keamanan</h3>
                <div className="form-group">
                  <label htmlFor="nik">NIK:</label>
                  <input type="text" autoComplete='off' id="nik" name="nik" className="form-control" value={nik} onChange={e => set_nik(e.target.value)} required />
                </div>

                <div>
                  <strong>Pertanyaan:</strong><br />
                  <p>{data?.pertanyaan}</p>
                </div>

                <div className="form-group">
                  <label htmlFor="jawaban">Jawaban: </label>
                  <input type="text" autoComplete='off' id="jawaban" name="jawaban" className="form-control" value={jawaban} onChange={e => set_jawaban(e.target.value)} required placeholder='Jawaban anda disini...' />
                </div>

              </div>
              {loading && <BarLoader size={20} color={"#4c04b8"} className="w-100" />}
              <div className="card-footer cek_akun__btn-check-group d-grid gap-2r" style={{ backgroundColor: '#4c56c0' }}>
                <button type='button' className='btn btn-secondary d-flex' onClick={() => direct('/')}><BsArrowLeftShort size={24} /> Kembali Ke Login</button>
                <button type='submit' className='btn btn-success cek_akun__btn-check-submit d-flex' disabled={loading}><BsCheck size={24} /> {loading ? 'Mohon tunggu...' : 'Selanjutnya'}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )

  const ViewPage3 = (
    <div className='container'>
      <div className="row">
        <div className='col-md-6 mx-auto my-5 login-desk'>
          <h1 className='text-center' style={{ color: '#4c56c0', fontWeight: 'bold' }}>AKUOI <small className='text-muted' style={{ fontSize: '50%' }}>v1.0</small></h1>
          <div className="card">
            <form onSubmit={handleCekTokenProses}>
              <div className="card-body">
                <h2>Lupa Token</h2>
                <hr style={{ borderTop: '3px solid #4c56c0' }} />
                <div className='alert alert-warning' style={{ border: "2px solid #4c56c0" }}>
                  <p className='m-0 p-0'> Berhasil mengatur ulang token pendaftaran<br/> Mohon untuk mengingat/mencatat token pendaftaran anda guna keperluan aktivasi</p>
                  <p className='m-0 p-0'>Token pendaftaran Anda:<span className='font-weight-bold'>{token}</span></p>
                </div>
              </div>
              {loading && <BarLoader size={20} color={"#4c04b8"} className="w-100" />}
              <div className="card-footer cek_akun__btn-check-group d-grid gap-2r" style={{ backgroundColor: '#4c56c0' }}>
                <button type='button' className='btn btn-secondary d-flex' onClick={() => direct('/')}><BsArrowLeftShort size={24} /> Kembali Ke Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )

  switch (view_page) {
    case 1:
      return ViewPage1
    case 2:
      return ViewPage2
    default:
      return ViewPage3
  }
}

export default Lupa_token