import React from 'react'
import { Routes, Route } from 'react-router-dom'
import LOGIN from '../pages/login'
import REGISTER from '../pages/register'
import CEK_AKUN from '../pages/cek_akun'
import LUPA_TOKEN from '../pages/lupa_token'
import RESET_SANDI from '../pages/reset_sandi'
import SETUP_AKUN from '../pages/setup_akun'
import Page from '../router/page'
import Pages from './page_dashboard'
import PAGE_DASHBOARD from '../pages/page_dashboard'
import PAGE_PROFILE from '../pages/page_profile'
import PAGE_DAFTAR_PENGGUNA from '../pages/page_daftar_pengguna'
import PAGE_DAFTAR_PENGGUNA_RINCIAN from '../pages/page_rincian'
const Index = () => {

  return (
    <Routes>
      <Route exact path="/" element={<Page mod={<LOGIN />} />} />
      <Route path="/register" element={<Page mod={<REGISTER />} />} />
      <Route path="/cek-akun" element={<Page mod={<CEK_AKUN />} />} />
      <Route path="/lupa-token" element={<Page mod={<LUPA_TOKEN/>} />} />
      <Route path="/reset-sandi" element={<Page mod={<RESET_SANDI/>} />} />
      <Route path="/atur-akun" element={<Page mod={<SETUP_AKUN />} />} />
      <Route path="/dashboard" element={<Pages mod={<PAGE_DASHBOARD />} />} />
      <Route path="/profile" element={<Pages mod={<PAGE_PROFILE />} />} />
      <Route exact path="/daftar-pengguna" element={<Pages mod={<PAGE_DAFTAR_PENGGUNA />} />} />
      <Route exact path="/daftar-pengguna/rincian" element={<Pages mod={<PAGE_DAFTAR_PENGGUNA_RINCIAN />} />} />
    </Routes>
  )
}

export default Index