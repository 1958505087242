import React from 'react'
import NAVBAR from '../template/navbar'
import SIDEBAR from '../template/sidebar'
import { content as ContentNotif } from '../util'
const Pages = ({ mod }) => {
  return (
    <>
      <div id="page-container" className="sidebar-o sidebar-dark enable-page-overlay side-scroll page-header-fixed main-content-boxed">
        <SIDEBAR />
        <NAVBAR />
        {mod}
        <ContentNotif />
      </div>
    </>
  )
}

export default Pages