import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const _props = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
}
const info = (param) => {
    return toast.info(param.msg, {
        ..._props
    });
}

const success = (param) => {
    return toast.success(param.msg, {
        ..._props
    });
}

const warning = (param) => {
    return toast.warning(param.msg, {
        ..._props
    });
}

const error = (param) => {
    return toast.error(param.msg, {
        ..._props
    });
}



const content = () => {
    return <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        style={{
            width:'34vw',
        }}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
}

export { content, info, success, warning, error }