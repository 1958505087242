import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BsCheck, BsArrowLeftShort, BsCheck2Circle } from 'react-icons/bs'
import { BarLoader } from 'react-spinners';
import '../assets/style.css'
import { HostContext } from '../context';
import { error, success } from '../util';

const Cek_akun = () => {
  const direct = useNavigate()
  const { state } = useLocation()
  const { HOST } = useContext(HostContext)
  const [nama_pengguna] = useState(state.username)
  const [sandi_baru, set_sandi_baru] = useState("")
  const [ulang_baru, set_ulang_baru] = useState("")
  const [loading, set_loading] = useState(false)

  const handleCekSubmit = async (event) => {
    event.preventDefault()
    set_loading(true)
    const formData = new FormData()
    formData.append('username', state.username)
    formData.append('sandi_baru', sandi_baru)
    formData.append('ulang_baru', ulang_baru)
    const requ = await fetch(`${HOST}/atur-akun`, { method: 'POST', headers: { "token": state.token }, body: formData })
    try {
      const resu = await requ.json()
      if (resu.code === 0) {
        success({msg:resu.message})
        set_loading(false)
        direct('/')
      } else {
        success({msg:resu.message})
        set_loading(false)
      }
    } catch (err) {
      error({ msg: err.message })
      set_loading(false)
    }
  }
  return (
    <div className='container'>
      <div className="row">
        <div className='col-md-6 mx-auto my-5 login-desk'>
          <h1 className='text-center' style={{ color: '#4c56c0', fontWeight: 'bold' }}>AKUOI <small className='text-muted' style={{ fontSize: '50%' }}>v1.0</small></h1>
          <div className="card">
            <form onSubmit={handleCekSubmit}>
              <div className="card-body">
                <h2>Atur Akun Pengguna</h2>
                <hr style={{ borderTop: '3px solid #4c56c0' }} />
                <div class="alert alert-primary">
                  <p className='p-0 m-0'><BsCheck2Circle /> Akun ditemukan</p>
                  <div class="border border-primary p-2">
                    <p className='p-0 m-0' style={{ fontSize: '14px' }}>Nama Pengguna</p>
                    <p className='p-0 m-0 font-weight-bold' style={{ fontSize: '14px' }}>{nama_pengguna}</p>
                  </div>
                  <p className='p-0 m-0'>Silahkan lakukan pengaturan kata sandi baru.</p>
                </div>
                <div className="form-group">
                  <label htmlFor="sandi_baru">Kata sandi Baru</label>
                  <input type="password" id="sandi_baru" name="sandi_baru" className="form-control m-0" value={sandi_baru} onChange={e => set_sandi_baru(e.target.value)} required />
                  <span className='text-muted m-0 p-0' style={{ fontSize: '12px' }}>Panjang karakter minimal <strong>6</strong> dan maksimal <strong>20</strong></span>
                </div>
                <div className="form-group">
                  <label htmlFor="ulang_baru">Konfirmasi Kata Sandi Baru</label>
                  <input type="password" id="ulang_baru" name="ulang_baru" className="form-control m-0" value={ulang_baru} onChange={e => set_ulang_baru(e.target.value)} required />
                  <span className='text-muted m-0 p-0' style={{ fontSize: '12px' }}>Panjang karakter minimal <strong>6</strong> dan maksimal <strong>20</strong></span>
                </div>
              </div>
              {loading && <BarLoader size={20} color={"#4c04b8"} className="w-100" />}
              <div className="card-footer cek_akun__btn-check-group d-grid gap-2r" style={{ backgroundColor: '#4c56c0' }}>
                <button type='button' className='btn btn-secondary d-flex' onClick={() => direct('/')}><BsArrowLeftShort size={24} /> Kembali Ke Login</button>
                <button type='submit' className='btn btn-success cek_akun__btn-check-submit d-flex' disabled={loading}><BsCheck size={24} /> {loading ? 'Mohon tunggu...' : 'Atur Sandi'}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cek_akun