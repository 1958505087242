import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext, HostContext } from '../context'
import { BsEyeFill } from 'react-icons/bs'
import { error } from '../util'
import { BeatLoader } from 'react-spinners'

const Page_daftar_pengguna = () => {
  document.title = "Daftar Pengguna | AKUOI"
  const direct = useNavigate();
  const { HOST } = useContext(HostContext)
  const { STATE_LOGIN } = useContext(AuthContext)
  const [daftar_pengguna, set_daftar_pengguna] = useState([])
  const [loaidng, set_loading] = useState(false)

  useEffect(() => {
    set_loading(true)
    const getDaftarPenggunaa = async () => {
      const requ = await fetch(`${HOST}/akun`, {
        method: 'GET',
        headers: {
          'xid': STATE_LOGIN.session
        }
      })
      try {
        const resu = await requ.json()
        console.log('daftar_pengguna', resu)
        if (resu.code === 0) {
          set_daftar_pengguna(resu.data)
        } else {
          error({ msg: resu.message })
        }
        set_loading(false)
      } catch (err) {
        error({ msg: err.message })
        set_loading(false)
      }
    }
    // setTimeout(() => { getDaftarPenggunaa() }, 50000)d
    getDaftarPenggunaa()
  }, [HOST, STATE_LOGIN])

  const handleViewDetail = (id) => {
    direct("/daftar-pengguna/rincian", { state: id })
  }

  if (!STATE_LOGIN.is_login) {
    return direct('/')
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">

          <div class="block">
            <div className="block-header">
              <h3 className='block-title'>Daftar Pengguna</h3>
            </div>
            <div className="block-content">
              <div className="table-responsive">
                <table className='table table-striped table-hover table-sm table-vcenter table-bordered'>
                  <thead className='bg-light'>
                    <tr>
                      <th>NIK</th>
                      <th>NIP</th>
                      <th>No.Telp</th>
                      <th>Unit Kerja</th>
                      <th>Jabatan</th>
                      <th>Akun/Rincian</th>
                    </tr>
                  </thead>
                  <thead>
                    {
                      loaidng
                        ?
                        <tr>
                          <td colSpan={6} className="text-center">
                            <span>
                              <BeatLoader color="#273fab" size={30} className="text-center" /> <br />
                              Mohon Tunggu...
                            </span>
                          </td>
                        </tr>
                        :
                        daftar_pengguna.length !== 0
                          ?
                          daftar_pengguna.map((v, i) => {
                            return <tr key={i}>
                              <td>{v.nik}</td>
                              <td>{v.nip}</td>
                              <td>{v.notelp}</td>
                              <td>{v.unitkerja}</td>
                              <td>{v.jabatan}</td>
                              <td>
                                <button className='btn btn-info btn-sm' onClick={handleViewDetail.bind(this, v.id)}> <BsEyeFill /> Lihat</button>
                              </td>
                            </tr>
                          })
                          :
                          <tr><td colSpan={6} className="text-center">Data Tidak Tersedia.</td></tr>
                    }
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page_daftar_pengguna