import React, { useEffect, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import { AuthContext, HostContext } from '../context'
import { error, success } from '../util'

const Page_rincian = () => {
  document.title = "Rincian | AKUOI"
  const { state } = useLocation()
  const { STATE_LOGIN, DISPATCH_LOGIN } = useContext(AuthContext)
  const { HOST } = useContext(HostContext)
  const ID = state;
  const SESSION = STATE_LOGIN.session;
  const [rincian_pengguna, set_rincian_pengguna] = useState({})
  const [loading, set_loading] = useState(true)
  const [view_file_ktp, set_view_file_ktp] = useState(false)
  const [view_file_rekomendasi, set_view_file_rekomendasi] = useState(false)

  const [file_ktp, set_file_ktp] = useState("")
  const [file_rekom, set_file_rekom] = useState("")

  const direct = useNavigate();
  useEffect(() => {
    set_loading(true)
    const getRincian = async () => {
      const requ = await fetch(`${HOST}/akun/rincian?regid=${ID}`, { method: "GET", headers: { "xid": SESSION } })
      try {
        const resu = await requ.json()
        if (resu.code === 0) {
          set_rincian_pengguna(resu.data)
        } else {
          error({ msg: resu.message })
        }
        set_loading(false)
      } catch (err) {
        error({ msg: err.message })
        set_loading(false)
      }
    }
    // setTimeout(() => { getRincian() }, 100000)
    getRincian()

  }, [HOST, ID, SESSION])

  const handleTolak = async () => {
    const requ = await fetch(`${HOST}/akun/aksi/tolak?regid=${ID}`, { method: 'PUT', headers: { "xid": SESSION } })
    try {
      const resu = await requ.json()
      if (resu.code === 0) {
        DISPATCH_LOGIN({ type: 'SET_NEW_SESSION', session: resu.session })
        success({ msg: resu.message })
        direct('/daftar-pengguna')
      } else {
        error({ msg: resu.message })
      }
    } catch (err) {
      error({ msg: err.message })
    }
  }

  const handleVerifikasi = async () => {

    const requ = await fetch(`${HOST}/akun/aksi/verifikasi?regid=${ID}`, {
      method: 'PUT',
      headers: { "xid": SESSION }
    })
    try {
      const resu = await requ.json()
      if (resu.code === 0) {
        console.log(resu)
        DISPATCH_LOGIN({ type: 'SET_NEW_SESSION', session: resu.session })
        success({ msg: resu.message })
        direct('/daftar-pengguna')
      } else {
        error({ msg: resu.message })
      }
    } catch (err) {
      error({ msg: err.message })
    }
  }

  const handleSetujui = async () => {
    const requ = await fetch(`${HOST}/akun/aksi/setujui?regid=${ID}`, {
      method: 'PUT',
      headers: { "xid": SESSION }
    })
    try {
      const resu = await requ.json()
      if (resu.code === 0) {
        console.log(resu)
        DISPATCH_LOGIN({ type: 'SET_NEW_SESSION', session: resu.session })
        success({ msg: resu.message })
        direct('/daftar-pengguna')
      } else {
        error({ msg: resu.message })
      }
    } catch (err) {
      error({ msg: err.message })
    }
  }

  const handleBuka = async () => {

    const requ = await fetch(`${HOST}/akun/aksi/buka?regid=${ID}`, {
      method: 'PUT',
      headers: { "xid": SESSION }
    })
    try {
      const resu = await requ.json()
      if (resu.code === 0) {
        DISPATCH_LOGIN({ type: 'SET_NEW_SESSION', session: resu.session })
        success({ msg: resu.message })
        direct('/daftar-pengguna')
      } else {
        error({ msg: resu.message })
      }
    } catch (err) {
      error({ msg: err.message })
    }
  }

  const handleFileKtp = async () => {
    set_view_file_rekomendasi(false)
    set_view_file_ktp(true)
    const requ = await fetch(`${HOST}/akun/rincian/berkas?regid=${ID}&file=ktp`, { method: 'GET', headers: { "xid": SESSION } })
    const resu = await requ.blob()
    const imageObjectURL = URL.createObjectURL(resu);
    set_file_ktp(imageObjectURL)
  }

  const handleFileRekom = async () => {
    set_view_file_ktp(false)
    set_view_file_rekomendasi(true)
    const requ = await fetch(`${HOST}/akun/rincian/berkas?regid=${ID}&file=rekomendasi`, { method: 'GET', headers: { "xid": SESSION } })
    const resu = await requ.blob()
    const imageObjectURL = URL.createObjectURL(resu);
    set_file_rekom(imageObjectURL)
  }

  const view_aksi = (gid) => {
    switch (gid) {
      case '3':
        return <li className='list-group-item'>
          <button className='btn btn-danger btn-lg' onClick={handleTolak}>Tolak</button>
          <button className='btn btn-info btn-lg mx-2' onClick={handleVerifikasi}>Verifikasi</button>
        </li>

      case '4':
        return <li className='list-group-item'>
          <button className='btn btn-danger btn-lg' onClick={handleTolak}>Tolak</button>
          <button className='btn btn-info btn-lg mx-2' onClick={handleSetujui}>Setujui</button>
        </li>

      case '5':
        return <li className='list-group-item'>
          <button className='btn btn-danger btn-lg' onClick={handleTolak}>Tolak</button>
          <button className='btn btn-info btn-lg mx-2' onClick={handleBuka}>Buka</button>
        </li>
      default:
        break
    }
  }

  if (!STATE_LOGIN.is_login) {
    return direct('/')
  }

  return (
    <div className="content">
      <div className="row">
        <div className="col-md-12">

          <div class="block">
            <div className="block-header">
              <h3 className='block-title'>Rincian Pengguna</h3>
            </div>
            <div className="block-content">
              {
                loading
                  ?
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <span>
                        <BeatLoader color="#273fab" size={30} /> <br />
                        Mohon Tunggu...
                      </span>
                    </div>
                  </div>

                  :
                  <ul class="list-group list-group-flush">
                    <li className='list-group-item'>
                      <h5 style={{ padding: 0, margin: 0 }}>Username:</h5>
                      <span>{rincian_pengguna.username}</span>
                    </li>
                    <li className='list-group-item'>
                      <h5 style={{ padding: 0, margin: 0 }}>Nama Depan:</h5>
                      <span>{rincian_pengguna.nama_depan}</span>
                    </li>
                    <li className='list-group-item'>
                      <h5 style={{ padding: 0, margin: 0 }}>Nama Akhir:</h5>
                      <span>{rincian_pengguna.nama_akhir}</span>
                    </li>
                    <li className='list-group-item'>
                      <h5 style={{ padding: 0, margin: 0 }}>NIK:</h5>
                      <span>{rincian_pengguna.nik}</span>
                    </li>
                    <li className='list-group-item'>
                      <h5 style={{ padding: 0, margin: 0 }}>NIP:</h5>
                      <span>{rincian_pengguna.nip}</span>
                    </li>
                    <li className='list-group-item'>
                      <h5 style={{ padding: 0, margin: 0 }}>Unit Kerja:</h5>
                      <span>{rincian_pengguna.unitkerja}</span>
                    </li>
                    <li className='list-group-item'>
                      <h5 style={{ padding: 0, margin: 0 }}>Jabatan:</h5>
                      <span>{rincian_pengguna.jabatan}</span>
                    </li>
                    <li className='list-group-item'>
                      <h5 className='mb-2' style={{ padding: 0, margin: 0 }}>Riwayat Status:</h5>
                      {rincian_pengguna.ditolak === "1" && <span style={{ padding: 5, margin: 2, color: '#fff', backgroundColor: '#d43159' }}>Ditolak</span>}
                      {rincian_pengguna.diverifikasi === "1" && <span style={{ padding: 5, margin: 2, color: '#333', backgroundColor: '#f7d439' }}>Diverifikasi</span>}
                      {rincian_pengguna.disetujui === "1" && <span style={{ padding: 5, margin: 2, color: '#fff', backgroundColor: '#346beb' }}>Disetujui</span>}
                      {rincian_pengguna.dibuka === "1" && <span style={{ padding: 5, margin: 2, color: '#333', backgroundColor: '#52f776' }}>Dibuka</span>}
                      {rincian_pengguna.diaktifkan === "1" && <span style={{ padding: 5, margin: 2, color: '#333', backgroundColor: '#68fcf0' }}>Diaktifkan</span>}
                      {
                        rincian_pengguna.diverifikasi === "0" &&
                        rincian_pengguna.disetujui === "0" &&
                        rincian_pengguna.dibuka === "0" &&
                        rincian_pengguna.diaktifkan === "0" && <span style={{ padding: 5, margin: 2, color: '#fff', backgroundColor: '#d43159' }}>Status Tidak Tersedia</span>}
                    </li>
                    <li className='list-group-item'>
                      <button className='btn btn-primary btn-lg' onClick={handleFileKtp}>File KTP/KARPEG/SWAFOTO</button>
                      <button className='btn btn-info btn-lg mx-2' onClick={handleFileRekom}>File Rekomendasi</button>
                    </li>
                    {view_file_ktp && <li className='list-group-item'>
                      <div class="card">
                        <div class="card-body">
                          {/* <img src={file_ktp} width={"50%"} alt="file" /> */}
                          <object data={file_ktp} aria-labelledby="object" width={'100%'} height={'500px'}/>
                        </div>
                      </div>
                    </li>}
                    {view_file_rekomendasi && <li className='list-group-item'>
                      <div class="card">
                        <div class="card-body">
                          {/* <img src={file_rekom} width={"50%"} alt="file" /> */}
                          <object data={file_rekom} aria-labelledby="object" width={'100%'} height={'500px'}/>
                        </div>
                      </div>
                    </li>}
                    {
                      view_aksi(STATE_LOGIN.gid)
                    }
                  </ul>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Page_rincian