import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { AuthContext, HostContext } from './context';
import { AuthInit, AuthReducers } from './reducer';
import Router from './router'
import { content as ContentNotif } from './util';
import 'react-tooltip/dist/react-tooltip.css'
const root = ReactDOM.createRoot(document.getElementById('root'));

const App = () => {
  // const HOST = "https://akuoi.kotamobagu.go.id/ws/index.php";
  const HOST = "../ws/index.php"
  const [STATE_LOGIN, DISPATCH_LOGIN] = React.useReducer(AuthReducers, AuthInit)
  return (
    <HostContext.Provider value={{ HOST }}>
      <AuthContext.Provider value={{ STATE_LOGIN, DISPATCH_LOGIN }}>
        <Router />
      </AuthContext.Provider>
    </HostContext.Provider>
  )
}

root.render(
  <React.StrictMode>
    <BrowserRouter basename='/'>
      <App />
      <ContentNotif />
    </BrowserRouter>
  </React.StrictMode>
);