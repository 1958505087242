const ls = localStorage;
const ul = "_USER_LOGIN_";
const ud = "_USER_DATA_";

const is_login = ls.getItem(ul)
const is_usdat = ls.getItem(ud)

let prepare_data = {}

if (is_login) {
  prepare_data = JSON.parse(is_usdat)
} else {
  prepare_data = {
    is_login: false,
    session: "",
    uid: "",
    mail: "",
    cn: "",
    displayName: "",
    gid: "",
    grup: "",
    profile: false,
    waktu: ""
  }
}

export const AuthInit = prepare_data

export const AuthReducers = (state, receive) => {
  const rec = receive;
  switch (rec.type) {
    case 'LOGIN':

      const set_data = {
        ...prepare_data,
        is_login: true,
        session: rec.session,
        uid: rec.uid,
        mail: rec.mail,
        cn: rec.cn,
        displayName: rec.displayName,
        gid: rec.gid,
        grup: rec.grup,
        profile: rec.profile,
        waktu: rec.waktu
      }

      ls.setItem(ul, set_data.is_login)
      ls.setItem(ud, JSON.stringify(set_data))
      return set_data

    case 'SET_NEW_SESSION':
      let old_data = JSON.parse(ls.getItem(ud));
      const update_data = { ...old_data, session: rec.session }
      ls.setItem(ud, JSON.stringify(update_data))
      return update_data

    case 'LOGOUT':
      ls.removeItem(ul)
      ls.removeItem(ud)
      return AuthInit

    default:
      return state
  }
}