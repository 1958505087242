import React from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context'

const Navbar = () => {
  const direct = useNavigate();
  const { STATE_LOGIN, DISPATCH_LOGIN } = React.useContext(AuthContext)
  const handleLogout = () => {
    DISPATCH_LOGIN({ type: 'LOGOUT' });
    direct('/');
  }
  return (
    <>
      {/* Header */}
      <header id="bg-dark">
        {/* Header Content */}
        <div className="content-header px-4 " style={{ backgroundColor: '#4c56c0' }}>
          {/* Left Section */}
          <div className="d-flex align-items-center">
            {/* Toggle Sidebar */}
            {/* Layout API, functionality initialized in Template._uiApiLayout() */}
            <button type="button" className="btn btn-sm btn-dual mr-2 d-lg-none" data-toggle="layout" data-action="sidebar_toggle">
              <i className="fa fa-fw fa-bars"></i>
            </button>
            {/* END Toggle Sidebar */}

            {/* Toggle Mini Sidebar */}
            {/* Layout API, functionality initialized in Template._uiApiLayout( */}
            <button type="button" className="btn btn-sm btn-dual mr-2 d-none d-lg-inline-block" data-toggle="layout" data-action="sidebar_mini_toggle">
              <i className="fa fa-fw fa-ellipsis-v"></i>
            </button>
            {/* END Toggle Mini Sidebar */}
          </div>
          {/* END Left Section */}

          {/* Right Section */}
          <div className="d-flex align-items-center">
            {/* User Dropdown */}
            <div className="dropdown d-inline-block ml-2">
              <button type="button" className="btn btn-sm btn-dual" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img className="rounded" src="assets/media/avatars/avatar10.jpg" alt="Header Avatar" style={{ width: '18px' }} />
                <span className="d-none d-sm-inline-block ml-1">{STATE_LOGIN.cn}</span>
                <i className="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
              </button>
              <div className="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm" aria-labelledby="page-header-user-dropdown">
                <div className="p-2">
                  <a className="dropdown-item d-flex align-items-center justify-content-between" onClick={handleLogout}>
                    <span>Keluar</span>
                    <i className="si si-logout ml-1"></i>
                  </a>
                </div>
              </div>
            </div>
            {/* END User Dropdown */}

            {/* END Toggle Side Overlay */}
          </div>
          {/* END Right Section */}
        </div>
        {/* END Header Content */}

        {/* Header Loader */}
        {/* Please check out the Loaders page under Components category to see examples of showing/hiding it */}
        <div id="page-header-loader" className="overlay-header bg-white">
          <div className="content-header">
            <div className="w-100 text-center">
              <i className="fa fa-fw fa-circle-notch fa-spin"></i>
            </div>
          </div>
        </div>
        {/* END Header Loader */}
      </header>
      {/* END Header */}
    </>
  )
}

export default Navbar